import React, { useState, useEffect } from 'react'
import { FaChevronDown, FaGithub, FaLinkedin } from 'react-icons/fa' // Aggiungi icone social

function App() {
  const [language, setLanguage] = useState('en') // Imposta di default l'inglese
  const [showDropdown, setShowDropdown] = useState(false) // Stato per mostrare/nascondere il dropdown

  // Funzione per cambiare la lingua quando viene selezionata una bandiera
  const changeLanguage = (lang) => {
    setLanguage(lang)
    setShowDropdown(false) // Nascondi il dropdown dopo la selezione
  }

  // Controlla la lingua del browser una sola volta quando il componente viene montato
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage

    if (browserLanguage.startsWith('fr')) {
      setLanguage('fr')
    } else if (browserLanguage.startsWith('it')) {
      setLanguage('it')
    } else {
      setLanguage('en')
    }
  }, [])

  // Testi in base alla lingua selezionata
  const getText = (lang) => {
    switch (lang) {
      case 'fr':
        return {
          title: "Bienvenue sur le site d'Alessandro RINAUDO",
          button: 'Télécharger le CV',
          role: 'Développeur Java',
          contact: 'Suivez-moi sur',
          language: 'Français',
          flag: './flags/france_flag.svg',
        }
      case 'it':
        return {
          title: 'Benvenuti nel sito di Alessandro RINAUDO',
          button: 'Scarica CV',
          role: 'Sviluppatore Java',
          contact: 'Seguimi su',
          language: 'Italiano',
          flag: './flags/italian_flag.svg',
        }
      default:
        return {
          title: "Welcome to Alessandro RINAUDO's website",
          button: 'Download CV',
          role: 'Java Developer',
          contact: 'Follow me on',
          language: 'English',
          flag: './flags/english_flag.svg',
        }
    }
  }

  const text = getText(language)

  return (
    <div className='min-h-screen flex flex-col justify-center items-center bg-blue-900 text-white px-4'>
      {/* Sezione delle bandiere per il cambio lingua */}
      <div className='absolute top-4 right-4'>
        {/* Bandiera principale (lingua corrente) */}
        <div
          className='flex items-center cursor-pointer hover:shadow-lg transition-shadow duration-300 ease-in-out'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <img src={text.flag} alt={text.language} className='w-8 h-5' />
          <span className='ml-2'>{text.language}</span>
          <FaChevronDown className='ml-2 text-teal-400' />
        </div>

        {/* Dropdown che mostra le altre lingue */}
        {showDropdown && (
          <div className='mt-2 bg-white text-black rounded shadow-lg'>
            <div
              className='flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
              onClick={() => changeLanguage('en')}
            >
              <img
                src='./flags/english_flag.svg'
                alt='English'
                className='w-6 h-4'
              />
              <span className='ml-2'>English</span>
            </div>
            <div
              className='flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
              onClick={() => changeLanguage('fr')}
            >
              <img
                src='./flags/france_flag.svg'
                alt='Français'
                className='w-6 h-4'
              />
              <span className='ml-2'>Français</span>
            </div>
            <div
              className='flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100'
              onClick={() => changeLanguage('it')}
            >
              <img
                src='./flags/italian_flag.svg'
                alt='Italiano'
                className='w-6 h-4'
              />
              <span className='ml-2'>Italiano</span>
            </div>
          </div>
        )}
      </div>

      <main className='flex flex-1 flex-col justify-center items-center space-y-6 text-center'>
        {/* Immagine profilo */}
        <img
          src='./profile.jpg' // Sostituisci con il percorso corretto della tua immagine
          alt='Alessandro'
          className='w-64 h-64 rounded-full object-cover mb-8'
        />

        <h1 className='text-2xl sm:text-3xl md:text-5xl font-bold mb-4'>
          {text.title}
        </h1>

        {/* Ruolo professionale */}
        <p className='text-lg sm:text-xl md:text-2xl italic mb-8'>
          {text.role}
        </p>

        {/* Freccia dinamica */}
        <div className='animate-bounce'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            className='w-12 h-12 text-teal-400'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M12 4v12m-6-6l6 6 6-6'
            />
          </svg>
        </div>

        {/* Bottone per scaricare il CV */}
        <a
          href='/cv/cv_alessandro-rinaudo.pdf' // Link al PDF montato nella cartella cv
          rel='noopener noreferrer'
          className='px-6 py-3 sm:px-8 sm:py-4 bg-teal-400 hover:bg-teal-500 text-sm sm:text-lg md:text-xl font-semibold rounded-lg shadow-lg transition transform hover:scale-105'
        >
          {text.button}
        </a>

        {/* Sezione Contatti - Social Media */}
        <div className='mt-10'>
          <p className='text-xl mb-4'>{text.contact}</p>
          <div className='flex space-x-6'>
            <a
              href='https://github.com/AlessandroRinaudo'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white hover:text-teal-400 transition'
            >
              <FaGithub className='w-8 h-8' />
            </a>
            <a
              href='https://www.linkedin.com/in/alessandro-rinaudo-403a001b3/'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white hover:text-teal-400 transition'
            >
              <FaLinkedin className='w-8 h-8' />
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}

export default App
